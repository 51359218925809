import styles from "./UserPromptsFilters.module.scss";

const UserPromptsFilters = ({
  handleFormSubmit,
  searchingValue,
  handleSearchingValueChange,
  isLoadingPrompts,
  categories,
  handleCategoryChange,
  selectedCategory,
  prompts,
  handleResetFilters,
}) => {
  return (
    <div className={styles.promptsFilters}>
      <form onSubmit={handleFormSubmit} className={styles.filtersForm}>
        <input
          type="text"
          value={searchingValue}
          onChange={handleSearchingValueChange}
          placeholder="Wyszukaj prompty..."
          className={styles.searchInput}
        />
        <button
          type="submit"
          className={styles.searchButton}
          disabled={isLoadingPrompts}
        >
          Wyszukaj
        </button>
      </form>
      <div className={styles.categories}>
        <p>Dostępne Kategorie:</p>
        <div className={styles.desktopCategories}>
          {categories.map((category, index) => (
            <div
              key={index}
              onClick={() => handleCategoryChange(category)}
              className={`${styles.category} ${
                selectedCategory === category ? styles.activeCategory : ""
              }`}
            >
              {category} (
              {prompts.filter((prompt) => prompt.category === category).length})
            </div>
          ))}
        </div>
        <select
          id="categorySelect"
          value={selectedCategory}
          className={styles.mobileCategories}
          onChange={(e) => handleCategoryChange(e.target.value)}
        >
          <option value="Wszystkie">Wszystkie</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={handleResetFilters}
        className={styles.resetFiltersButton}
      >
        Resetuj filtry
      </button>
    </div>
  );
};

export default UserPromptsFilters;
