import React, { useState, useEffect } from "react";
import styles from "./PromptFixer.module.scss";
import InformationBanner from "../../components/InformationBanner";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { useAuth } from "../../context/AuthContext";
import { useContent } from "../../context/ContentContext";
import MembershipRequired from "../../components/MembershipRequired";

import { getPromptActions } from "./utils";

const override = {
  display: "block",
  margin: "0 auto",
};

const PromptFixerPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [textToSend, setTextToSend] = useState("");
  const [level, setLevel] = useState("basic");
  const [generatedPrompt, setGeneratedPrompt] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const { user } = useAuth();
  const { content } = useContent();

  const loadingNotificationId = React.useRef(null);

  const promptActions = getPromptActions({
    setTextToSend,
    setLevel,
    generatedPrompt,
    setIsCopied,
    textToSend,
    setIsLoading,
    isLoading,
    setGeneratedPrompt,
    loadingNotificationId,
    level,
  });

  useEffect(() => {
    const enhancedPrompt = localStorage.getItem("enhancedPrompt");
    if (enhancedPrompt) {
      setTextToSend(enhancedPrompt);
    }
  }, []);

  if (!content) return null;

  return (
    <div className={styles.wrapper}>
      <InformationBanner
        heading={content.promptFixer.heroHeader}
        text={content.promptFixer.heroContent}
      />
      <div className={styles.features}>
        <div className={styles.feature}>✨ Dynamiczne Generowanie</div>
        <div className={styles.feature}>🤖 100% w pełni dostosowywalne</div>
        <div className={styles.feature}>✅ Edytuj i pobieraj</div>
        <div className={styles.feature}>🚀 Generuj, korzystaj, udostępniaj</div>
      </div>
      {user.membershipActive ? (
        <>
          <div className={styles.userPrompt}>
            <div className={styles.userPromptWrapper}>
              <input
                type="text"
                value={textToSend}
                onChange={promptActions.handleTextChange}
                onKeyUp={promptActions.handleKeyPress}
                placeholder={content.promptFixer.searchBoxPlaceholder}
              />
              <button
                onClick={promptActions.handleGeneratePrompt}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ClipLoader
                    color={"#ffffff"}
                    loading={isLoading}
                    css={override}
                    size={20}
                  />
                ) : (
                  content.promptFixer.searchBtnText
                )}
              </button>
            </div>
            <div className={styles.level}>
              <label>
                <input
                  type="radio"
                  value="basic"
                  checked={level === "basic"}
                  onChange={promptActions.handleLevelChange}
                />
                Podstawowy
              </label>
              <label>
                <input
                  type="radio"
                  value="advanced"
                  checked={level === "advanced"}
                  onChange={promptActions.handleLevelChange}
                />
                Zaawansowany
              </label>
              <label>
                <input
                  type="radio"
                  value="professional"
                  checked={level === "professional"}
                  onChange={promptActions.handleLevelChange}
                />
                Profesjonalny
              </label>
            </div>
          </div>
          <div className={styles.generatedPromptWrapper}>
            <p>{content.promptFixer.generatedPromptHeader}</p>
            <textarea
              value={generatedPrompt}
              readOnly
              onClick={promptActions.handleCopyGeneratedPrompt}
              placeholder={content.promptFixer.generatedPromptPlaceholder}
              className={styles.generatedPrompt}
            />
            <button
              onClick={promptActions.handleCopyGeneratedPrompt}
              className={styles.copyButton}
              disabled={!generatedPrompt}
            >
              {isCopied ? "Skopiowano!" : "Kopiuj Prompt"}
            </button>
          </div>
        </>
      ) : (
        <MembershipRequired />
      )}
    </div>
  );
};

export default PromptFixerPage;
