import React, { createContext, useState, useContext, useEffect } from "react";
import { getMidjourneyPrompts } from "../services/api";
import { useAuth } from "./AuthContext";

const MidjourneyPromptsContext = createContext();

export const useMidjourneyPrompts = () => useContext(MidjourneyPromptsContext);

export const MidjourneyPromptsProvider = ({ children }) => {
  const [midjourneyPrompts, setMidjourneyPrompts] = useState([]);
  const [freePrompts, setFreePrompts] = useState([]);
  const [isContextErrorOccurred, setIsContextErrorOccurred] = useState(false);
  const [isContextLoading, setIsContextLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchMidjourneyPrompts = async () => {
      setIsContextLoading(true);
      try {
        const { prompts, isErrorOccured } = await getMidjourneyPrompts();

        const freePromptsArray = prompts.filter(
          (prompt) => prompt.free === true
        );

        setMidjourneyPrompts(prompts);
        setFreePrompts(freePromptsArray);

        if (isErrorOccured) {
          setIsContextErrorOccurred(true);
        }
      } catch (error) {
        setIsContextErrorOccurred(true);
      }
      setIsContextLoading(false);
    };

    if (user) {
      fetchMidjourneyPrompts();
    }
  }, [user]);

  return (
    <MidjourneyPromptsContext.Provider
      value={{
        midjourneyPrompts,
        freePrompts,
        isContextErrorOccurred,
        isContextLoading,
      }}
    >
      {children}
    </MidjourneyPromptsContext.Provider>
  );
};
