import { generatePrompt } from "../../services/api";
import { toast } from "react-toastify";

export const getPromptActions = ({
  setTextToSend,
  setLevel,
  generatedPrompt,
  setIsCopied,
  textToSend,
  setIsLoading,
  isLoading,
  setGeneratedPrompt,
  loadingNotificationId,
  level,
}) => {
  const handleTextChange = (event) => {
    setTextToSend(event.target.value);
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleCopyGeneratedPrompt = () => {
    if (!generatedPrompt) {
      toast.warn("Brak wygenerowanego prompta do skopiowania.");
      return;
    }

    const settingsLanguage =
      localStorage.getItem("responseLanguage") || "polski";

    navigator.clipboard.writeText(
      `${generatedPrompt}\n\nOdpowiedz przygotuj w języku ${settingsLanguage}`
    );

    setIsCopied(true);
    toast.info("Prompt został skopiowany!");

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const handleGeneratePrompt = async () => {
    if (!textToSend) {
      toast.warn("Proszę wpisać tekst przed generowaniem prompta.");
      return;
    }

    setIsLoading(true);
    setIsCopied(false);
    setGeneratedPrompt("");

    loadingNotificationId.current = toast.info("Generowanie w toku...", {
      autoClose: false,
    });

    try {
      const response = await generatePrompt(textToSend, level);
      setGeneratedPrompt(response.message);
      toast.success("Prompt został wygenerowany pomyślnie!");
    } catch (error) {
      console.error("Error generating prompt:", error);
      toast.error("Wystąpił błąd podczas generowania prompta.");
    } finally {
      setIsLoading(false);
      localStorage.removeItem("enhancedPrompt");

      if (loadingNotificationId.current) {
        toast.dismiss(loadingNotificationId.current);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !isLoading) {
      handleGeneratePrompt();
    }
  };

  return {
    handleTextChange,
    handleLevelChange,
    handleCopyGeneratedPrompt,
    handleGeneratePrompt,
    handleKeyPress,
  };
};
