import React from "react";
import { Helmet } from "react-helmet";
import "@fontsource/poppins";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { RequireAuth } from "./routes/RequireAuth";

import { AuthProvider } from "./context/AuthContext";
import "@csstools/normalize.css";

import { PromptsProvider } from "./context/PromptsContext";
import { MidjourneyPromptsProvider } from "./context/MidjourneyPromptsContext";
import { FavoritesProvider } from "./context/FavoritesContext";
import { ContentProvider } from "./context/ContentContext";

import PromptsPage from "./views/PromptsPage";
import LoginPage from "./views/LoginPage";
import FavoritesPromptsPage from "./views/FavoritesPromptsPage";
import UserPromptsPage from "./views/UserPromptsPage";
import MidjourneyPromptsPage from "./views/MidjourneyPromptsPage";

import Menu from "./components/Menu";
import TopBanner from "./components/TopBanner";
import NotFoundPage from "./views/NotFoundPage";

import { ToastContainer } from "react-toastify";
import PromptFixerPage from "./views/PromptFixerPage";
import GenerateMidjourneyPromptPage from "./views/GenerateMidjourneyPrompt";
import NotRequireAuth from "./routes/NotRequireAuth";
import AppRouter from "./routes/AppRouter";
import Footer from "./components/Footer";

const AuthenticatedComponents = ({ children }) => (
  <>
    <TopBanner />
    <Menu />
    <div className="children">{children}</div>
    <Footer />
  </>
);

function App() {
  return (
    <>
      <Helmet>
        <script src="https://cdn.lordicon.com/lordicon.js"></script>
      </Helmet>
      <BrowserRouter
        future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
      >
        <AppRouter />
        <AuthProvider>
          <FavoritesProvider>
            <PromptsProvider>
              <MidjourneyPromptsProvider>
                <ContentProvider>
                  <ToastContainer />
                  <Routes>
                    <Route
                      path="/login"
                      element={
                        <NotRequireAuth>
                          <LoginPage />
                        </NotRequireAuth>
                      }
                    />
                    <Route
                      path="/"
                      element={
                        <RequireAuth>
                          <AuthenticatedComponents>
                            <PromptsPage />
                          </AuthenticatedComponents>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/lista-promptow-midjourney"
                      element={
                        <RequireAuth>
                          <AuthenticatedComponents>
                            <MidjourneyPromptsPage />
                          </AuthenticatedComponents>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/ulubione"
                      element={
                        <RequireAuth>
                          <AuthenticatedComponents>
                            <FavoritesPromptsPage />
                          </AuthenticatedComponents>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/prompt-fixer"
                      element={
                        <RequireAuth>
                          <AuthenticatedComponents>
                            <PromptFixerPage />
                          </AuthenticatedComponents>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/generate-midjourney-prompt"
                      element={
                        <RequireAuth>
                          <AuthenticatedComponents>
                            <GenerateMidjourneyPromptPage />
                          </AuthenticatedComponents>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/wlasne-prompty"
                      element={
                        <RequireAuth>
                          <AuthenticatedComponents>
                            <UserPromptsPage />
                          </AuthenticatedComponents>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="*"
                      element={
                        <RequireAuth>
                          <AuthenticatedComponents>
                            <NotFoundPage />
                          </AuthenticatedComponents>
                        </RequireAuth>
                      }
                    />
                  </Routes>
                </ContentProvider>
              </MidjourneyPromptsProvider>
            </PromptsProvider>
          </FavoritesProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
