import React, { useState, useEffect } from "react";
import styles from "./TopBanner.module.scss";
import { useContent } from "../../context/ContentContext";

const TopBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const { content } = useContent();

  useEffect(() => {
    const isBannerClosed = localStorage.getItem("isBannerClosed");
    if (isBannerClosed === "true") {
      setIsBannerVisible(false);
    }
  }, []);

  if (!content) return null;

  return isBannerVisible ? (
    <div className={styles.banner}>
      <div className={styles.bannerWrapper}>
        <div className={styles.textWrapper}>
          <p className={styles.text}>{content.greenHeader}</p>
          <a
            href="https://campusai.pl/"
            target="_blank"
            rel="noreferrer"
            className={styles.button}
          >
            {content.greenHeaderButton}
          </a>
        </div>
      </div>
    </div>
  ) : null;
};

export default TopBanner;
