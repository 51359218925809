import React, { createContext, useState, useContext, useEffect } from "react";
import { getPrompts } from "../services/api";
import { useAuth } from "./AuthContext";

const PromptsContext = createContext();

export const usePrompts = () => useContext(PromptsContext);

export const PromptsProvider = ({ children }) => {
  const [prompts, setPrompts] = useState([]);
  const [freePrompts, setFreePrompts] = useState([]);
  const [isContextErrorOccurred, setIsContextErrorOccurred] = useState(false);
  const [isContextLoading, setIsContextLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchPrompts = async () => {
      setIsContextLoading(true);
      try {
        const { prompts, isErrorOccured } = await getPrompts();

        const reversedPrompts = prompts.slice().reverse();

        const freePromptsArray = reversedPrompts.filter(
          (prompt) => prompt.free === true
        );

        setPrompts(reversedPrompts);
        setFreePrompts(freePromptsArray);

        if (isErrorOccured) {
          setIsContextErrorOccurred(true);
        }
      } catch (error) {
        setIsContextErrorOccurred(true);
      }
      setIsContextLoading(false);
    };

    if (user) {
      fetchPrompts();
    }
  }, [user]);

  return (
    <PromptsContext.Provider
      value={{ prompts, freePrompts, isContextErrorOccurred, isContextLoading }}
    >
      {children}
    </PromptsContext.Provider>
  );
};
