import DefaultSpinner from "../DefaultSpinner";
import styles from "./PromptsLoading.module.scss";

const PromptsLoading = () => {
  return (
    <div className={styles.wrapper}>
      <DefaultSpinner />
      <p> Trwa ładowanie promptów...</p>
    </div>
  );
};

export default PromptsLoading;
