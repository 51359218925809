import React, { memo, useState, useEffect } from "react";
import { replaceVariablesWithLabels } from "../../helpers";
import CopyIcon from "../../assets/CopyIcon";
import CogIcon from "../../assets/CogIcon";
import StarIcon from "../../assets/StarIcon";
import EmptyStarIcon from "../../assets/EmptyStarIcon";
import styles from "./PromptsItem.module.scss";

const PromptsItem = memo(
  ({
    prompt,
    onCopy,
    onOpenModal,
    onAddToFavorites,
    onRemoveFromFavorites,
    favorites,
  }) => {
    const [isFavorite, setIsFavorite] = useState(false);

    const promptWithLabels = replaceVariablesWithLabels(
      prompt.prompt,
      prompt.variables
    );

    useEffect(() => {
      const newFavorites = favorites || [];
      setIsFavorite(newFavorites.includes(prompt._id));
    }, [prompt._id, favorites]);

    const handleFavoriteClick = async () => {
      if (isFavorite) {
        await onRemoveFromFavorites(prompt._id);
      } else {
        await onAddToFavorites(prompt._id);
        setIsFavorite(true);
      }
    };

    return (
      <div className={styles.prompt}>
        {isFavorite ? (
          <StarIcon onClick={handleFavoriteClick} className={styles.starIcon} />
        ) : (
          <EmptyStarIcon
            onClick={handleFavoriteClick}
            className={styles.emptyStarIcon}
          />
        )}
        <div className={styles.promptDetails}>
          <p className={styles.subCategory}>{prompt.subCategory}</p>
          <div className={styles.promptContent}>
            <p>{promptWithLabels}</p>
          </div>
        </div>
        <div className={styles.promptIcons}>
          <CopyIcon
            onClick={() => onCopy(promptWithLabels)}
            className={styles.icon}
          />
          <CogIcon
            onClick={() => onOpenModal(promptWithLabels)}
            className={styles.icon}
          />
        </div>
      </div>
    );
  }
);

export default PromptsItem;
