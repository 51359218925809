import React, { useEffect, useState } from "react";
import styles from "./UserPrompts.module.scss";
import ConfirmationModal from "../../components/ConfirmationModal";
import UserPromptsModal from "./components/UserPromptsModal";
import UserPromptsItem from "./components/UserPromptsItem";
import InformationBanner from "../../components/InformationBanner";
import { useContent } from "../../context/ContentContext";
import {
  getUniqueCategories,
  handleCopyButtonClick,
  getPaginationActions,
  getFiltersActions,
  getModalActions,
  promptsPerPage,
  getPromptsActions,
} from "./utils";
import Pagination from "../../components/Pagination";
import PromptsLoading from "../../components/Loading/PromptsLoading";
import Error from "../../components/Errors/Error";
import UserPromptsFilters from "../../components/Filters/UserPromptsFilters";
import AddNewPrompt from "./components/AddNewPrompt";
import FoundResults from "../../components/Filters/FoundResults";

const UserPromptsPage = () => {
  const [errors, setErrors] = useState({
    fetchingPrompts: false,
    noPrompts: false,
    filteringPrompts: false,
  });
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(true);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [promptsToShow, setPromptsToShow] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchingValue, setSearchingValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState({
    category: "Wszystkie",
  });
  const [modalData, setModalData] = useState({
    isOpen: false,
    prompt: "",
    category: "",
    id: "",
  });
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [promptToRemoveId, setPromptToRemoveId] = useState(null);
  const [newPrompt, setNewPrompt] = useState("");

  const { content } = useContent();

  const paginationActions = getPaginationActions({
    setCurrentPage,
    filteredPrompts,
    setPromptsToShow,
  });

  const filtersActions = getFiltersActions({
    setIsLoadingPrompts,
    prompts,
    selectedCategory,
    searchingValue,
    setFilteredPrompts,
    setCurrentPage,
    setPromptsToShow,
    setErrors,
    setSearchingValue,
    setSelectedCategory,
  });

  const modalActions = getModalActions({
    setModalData,
    promptToRemoveId,
    prompts,
    setPrompts,
    filtersActions,
    setIsRemoveModalOpen,
  });

  const promptsActions = getPromptsActions({
    prompts,
    setPrompts,
    filtersActions,
    modalActions,
    setIsLoadingPrompts,
    setErrors,
    setFilteredPrompts,
    setPromptsToShow,
    newPrompt,
    setNewPrompt,
    setPromptToRemoveId,
    setIsRemoveModalOpen,
  });

  useEffect(() => {
    promptsActions.fetchPrompts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (prompts.length > 0) {
      const uniqueCategories = getUniqueCategories(prompts);
      setCategories(uniqueCategories);
    }
  }, [prompts]);

  useEffect(() => {
    if (prompts.length > 0) {
      filtersActions.handleSearchByValue(prompts);
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

  if (errors.fetchingPrompts) return <Error />;

  if (!content) return null;

  return (
    <div className={styles.wrapper}>
      {modalData.isOpen && (
        <UserPromptsModal
          modalData={modalData}
          onClose={modalActions.closeModal}
          onSave={promptsActions.handleEditUserPrompt}
        />
      )}
      {isRemoveModalOpen && (
        <ConfirmationModal
          modalData={{
            message: "Czy na pewno chcesz usunąć prompta?",
            confirmText: "Usuń",
            cancelText: "Anuluj",
          }}
          isOpen={isRemoveModalOpen}
          onConfirm={modalActions.confirmRemoveFromUser}
          onClose={() => setIsRemoveModalOpen(false)}
        />
      )}
      <InformationBanner
        heading={content.ownPrompts.heroHeader}
        text={content.ownPrompts.heroContent}
      />

      <div className={styles.promptsPage}>
        <UserPromptsFilters
          handleFormSubmit={filtersActions.handleFormSubmit}
          searchingValue={searchingValue}
          handleSearchingValueChange={filtersActions.handleSearchingValueChange}
          isLoadingPrompts={isLoadingPrompts}
          categories={categories}
          handleCategoryChange={filtersActions.handleCategoryChange}
          selectedCategory={selectedCategory.category}
          prompts={prompts}
          handleResetFilters={filtersActions.handleResetFilters}
        />

        <div className={styles.promptsList}>
          <div className={styles.userPrompt}>
            <AddNewPrompt
              newPrompt={newPrompt}
              promptsActions={promptsActions}
              content={content}
              isLoadingPrompts={isLoadingPrompts}
            />
          </div>
          {!isLoadingPrompts ? (
            <div className={styles.promptsListWrapper}>
              <FoundResults count={filteredPrompts.length} />
              {promptsToShow.map((promptToShow) => (
                <UserPromptsItem
                  key={promptToShow.id}
                  prompt={promptToShow}
                  onCopy={handleCopyButtonClick}
                  onOpenModal={modalActions.openModal}
                  onRemoveFromUser={() =>
                    promptsActions.removeFromUser(promptToShow.id)
                  }
                />
              ))}
              {promptsToShow.length > 0 && (
                <Pagination
                  handlePageChange={paginationActions.handlePageChange}
                  currentPage={currentPage}
                  promptsLength={filteredPrompts.length}
                  itemsPerPage={promptsPerPage}
                />
              )}
            </div>
          ) : (
            <PromptsLoading />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPromptsPage;
