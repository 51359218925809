import React from "react";
import styles from "./InformationBanner.module.scss";

const InformationBanner = ({ heading, text }) => {
  return (
    <div className={styles.banner}>
      <p className={styles.heading}>{heading}</p>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default InformationBanner;
