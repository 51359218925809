import styles from "./MembershipRequired.module.scss";

const MembershipRequired = () => {
  return (
    <div className={styles.membershipErrorContainer}>
      <h2>
        Funkcjonalność dostępna tylko dla użytkowników z aktywnym członkostwem
      </h2>
      <p>
        Do pełnego korzystania z tej funkcji zaloguj się i aktywuj członkostwo.
      </p>
      <a href="https://app.campusai.pl/" target="_blank" rel="noreferrer">
        Powrót do CampusAI
      </a>
    </div>
  );
};

export default MembershipRequired;
