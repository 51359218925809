import React, { useEffect, useState } from "react";
import { getUniqueCategoriesWithSubcategories } from "../../helpers";
import { useAuth } from "../../context/AuthContext";
import { useFavorites } from "../../context/FavoritesContext";
import { usePrompts } from "../../context/PromptsContext";
import PromptsItem from "../../components/PromptsItem";
import ConfirmationModal from "../../components/ConfirmationModal";
import PromptsModal from "../../components/PromptsModal";
import { useContent } from "../../context/ContentContext";
import styles from "./Prompts.module.scss";
import InformationBanner from "../../components/InformationBanner";
import FoundResults from "../../components/Filters/FoundResults";

import {
  copyModifiedPrompt,
  handleCopyButtonClick,
  fetchPrompts,
  getPaginationActions,
  getFiltersActions,
  getModalActions,
  promptsPerPage,
  getPromptsActions,
} from "./utils";
import Pagination from "../../components/Pagination";
import PromptsLoading from "../../components/Loading/PromptsLoading";
import PromptsFilters from "../../components/Filters/PromptsFilters";
import Error from "../../components/Errors/Error";

const PromptsPage = () => {
  const [errors, setErrors] = useState({
    fetchingPrompts: false,
    noPrompts: false,
    filteringPrompts: false,
  });
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(true);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [promptsToShow, setPromptsToShow] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchingValue, setSearchingValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState({
    category: "Wszystkie",
    subCategory: null,
    type: "main",
  });
  const [modalData, setModalData] = useState({
    isOpen: false,
    variables: [],
    basePrompt: "",
    modifiedPrompt: "",
  });
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [promptToRemoveId, setPromptToRemoveId] = useState(null);

  const { user } = useAuth();
  const { content } = useContent();

  const { prompts, freePrompts, isContextErrorOccurred, isContextLoading } =
    usePrompts();
  const { favorites, updateFavoritePrompts } = useFavorites();

  const paginationActions = getPaginationActions({
    setCurrentPage,
    filteredPrompts,
    setPromptsToShow,
  });

  const filtersActions = getFiltersActions({
    setIsLoadingPrompts,
    prompts,
    selectedCategory,
    searchingValue,
    setFilteredPrompts,
    setCurrentPage,
    setPromptsToShow,
    setErrors,
    setSearchingValue,
    setSelectedCategory,
  });

  const modalActions = getModalActions({
    modalData,
    setModalData,
    user,
    updateFavoritePrompts,
    setIsRemoveModalOpen,
    promptToRemoveId,
  });

  const promptsActions = getPromptsActions({
    updateFavoritePrompts,
    setPromptToRemoveId,
    setIsRemoveModalOpen,
  });

  useEffect(() => {
    fetchPrompts(
      isContextLoading,
      setIsLoadingPrompts,
      isContextErrorOccurred,
      setErrors,
      user,
      prompts,
      freePrompts,
      setFilteredPrompts,
      promptsPerPage,
      setPromptsToShow
    );
  }, [
    user,
    prompts,
    freePrompts,
    isContextErrorOccurred,
    isContextLoading,
    user.membershipActive,
  ]);

  useEffect(() => {
    if (prompts.length > 0) {
      const uniqueCategories = getUniqueCategoriesWithSubcategories(prompts);
      setCategories(uniqueCategories);
    }
  }, [prompts]);

  useEffect(() => {
    if (prompts.length > 0) {
      filtersActions.handleSearchByValue();
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

  if (errors.fetchingPrompts) return <Error />;

  if (!content) return null;

  return (
    <div className={styles.promptsPageWrapper}>
      {modalData.isOpen && (
        <PromptsModal
          modalData={modalData}
          onVariableChange={modalActions.handleVariableInputChange}
          onClose={modalActions.closeModal}
          onCopy={() => copyModifiedPrompt({ modalData })}
        />
      )}

      {isRemoveModalOpen && (
        <ConfirmationModal
          modalData={{
            message: "Czy na pewno chcesz usunąć prompta z ulubionych?",
            confirmText: "Usuń",
            cancelText: "Anuluj",
          }}
          isOpen={isRemoveModalOpen}
          onConfirm={modalActions.confirmRemoveFromFavorites}
          onClose={() => setIsRemoveModalOpen(false)}
        />
      )}
      <InformationBanner
        heading={content.promptList.contentHeader}
        text={content.promptList.heroContent}
      />
      <div className={styles.promptsPage}>
        <PromptsFilters
          handleFormSubmit={filtersActions.handleFormSubmit}
          searchingValue={searchingValue}
          handleSearchingValueChange={filtersActions.handleSearchingValueChange}
          isLoadingPrompts={isLoadingPrompts}
          categories={categories}
          handleCategoryChange={filtersActions.handleCategoryChange}
          selectedCategory={selectedCategory}
          prompts={prompts}
          handleResetFilters={filtersActions.handleResetFilters}
        />

        <div className={styles.promptsList}>
          {!isLoadingPrompts ? (
            <div className={styles.promptsListWrapper}>
              <FoundResults count={filteredPrompts.length} />
              {promptsToShow.map((promptToShow) => (
                <PromptsItem
                  key={promptToShow._id}
                  prompt={promptToShow}
                  onCopy={handleCopyButtonClick}
                  onOpenModal={modalActions.openModal}
                  onAddToFavorites={() =>
                    promptsActions.handleAddToFavorites(promptToShow._id)
                  }
                  onRemoveFromFavorites={() =>
                    promptsActions.removeFromFavorites(promptToShow._id)
                  }
                  favorites={favorites}
                />
              ))}
              {promptsToShow.length > 0 && (
                <Pagination
                  handlePageChange={paginationActions.handlePageChange}
                  currentPage={currentPage}
                  promptsLength={filteredPrompts.length}
                  itemsPerPage={promptsPerPage}
                />
              )}
            </div>
          ) : (
            <PromptsLoading />
          )}
        </div>
      </div>
    </div>
  );
};

export default PromptsPage;
