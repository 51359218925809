export const getFromCookies = (name) => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [cookieName, value] = cookie.split("=").map((c) => c.trim());
    if (cookieName === name) {
      return decodeURIComponent(value);
    }
  }
  return null;
};

export const setCookie = (name, value, options) => {
  let cookieString = `${name}=${encodeURIComponent(value)}`;
  for (const [optionKey, optionValue] of Object.entries(options)) {
    cookieString += `; ${optionKey}`;
    if (optionValue !== true) {
      cookieString += `=${optionValue}`;
    }
  }
  document.cookie = cookieString;
};

export const removeCookie = (name) => {
  setCookie(name, "", { path: "/", expires: "Thu, 01 Jan 1970 00:00:00 UTC" });
};
