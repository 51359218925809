import React from "react";
import CopyIcon from "../../../../assets/CopyIcon";
import CogIcon from "../../../../assets/CogIcon";
import styles from "./UserPromptsItem.module.scss";
import DeleteIcon from "../../../../assets/DeleteIcon";

const UserPromptsItem = ({ prompt, onCopy, onOpenModal, onRemoveFromUser }) => {
  return (
    <div className={styles.prompt}>
      <DeleteIcon onClick={onRemoveFromUser} className={styles.deleteIcon} />
      <div className={styles.promptDetails}>
        <p className={styles.subCategory}>{prompt.category}</p>
        <div className={styles.promptContent}>
          <p>{prompt.content}</p>
        </div>
      </div>
      <div className={styles.promptIcons}>
        <CopyIcon
          onClick={() => onCopy(prompt.content)}
          className={styles.icon}
        />
        <CogIcon onClick={() => onOpenModal(prompt)} className={styles.icon} />
      </div>
    </div>
  );
};

export default UserPromptsItem;
