import { toast } from "react-toastify";

import {
  extractTextBetweenSquareBrackets,
  replaceVariablesWithLabels,
} from "../../../../helpers";
export const preparePromptWithLabels = (prompt = "", variables = {}) => {
  return replaceVariablesWithLabels(prompt, variables);
};

export const startEditing = (promptWithLabels, setEditedPromptData) => {
  const extractedTextArray = extractTextBetweenSquareBrackets(promptWithLabels);

  setEditedPromptData({
    isOpen: true,
    variables: extractedTextArray,
    basePrompt: promptWithLabels,
    modifiedPrompt: promptWithLabels,
  });
};

export const getModalActions = ({
  editedPromptData,
  setEditedPromptData,
  closeModal,
  navigate,
}) => {
  const handleVariableInputChange = (variableName, value) => {
    const updatedEditedPromptData = { ...editedPromptData };

    const variableIndex = updatedEditedPromptData.variables.findIndex(
      (variable) => variable.label === variableName
    );

    if (variableIndex !== -1) {
      updatedEditedPromptData.variables[variableIndex].value = value;

      updatedEditedPromptData.modifiedPrompt =
        updatedEditedPromptData.basePrompt;

      updatedEditedPromptData.variables.forEach((variable) => {
        const variablePattern = new RegExp(
          variable.label.replace(/[[\]]/g, "\\$&"),
          "g"
        );

        updatedEditedPromptData.modifiedPrompt =
          updatedEditedPromptData.modifiedPrompt.replace(
            variablePattern,
            variable.value || variable.label
          );
      });

      setEditedPromptData(updatedEditedPromptData);
    }
  };
  const closeEditing = () => {
    setEditedPromptData({
      isOpen: false,
      variables: [],
      modifiedPrompt: "",
    });
  };

  const copyModifiedPrompt = () => {
    const settingsLanguage =
      localStorage.getItem("responseLanguage") || "polski";

    navigator.clipboard.writeText(
      `${editedPromptData.modifiedPrompt}\n\nOdpowiedz przygotuj w języku ${settingsLanguage}`
    );

    toast.success("Prompt został skopiowany do schowka!");
  };

  const copyOriginalPrompt = (promptToCopy) => {
    const settingsLanguage =
      localStorage.getItem("responseLanguage") || "polski";

    navigator.clipboard.writeText(
      `${promptToCopy}\n\nOdpowiedz przygotuj w języku ${settingsLanguage}`
    );

    toast.success("Prompt został skopiowany do schowka!");
  };

  const handleModalClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const handleEnhancePrompt = () => {
    const enhancedPrompt = editedPromptData.modifiedPrompt;
    localStorage.setItem("enhancedPrompt", enhancedPrompt);
    closeEditing();
    navigate("/prompt-fixer");
    toast.success("Prompt został przeniesiony do promptfixera!");
  };
  return {
    handleVariableInputChange,
    closeEditing,
    copyModifiedPrompt,
    copyOriginalPrompt,
    handleModalClick,
    handleEnhancePrompt,
  };
};
