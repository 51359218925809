import React, { useEffect, useState } from "react";
import { getUniqueCategories } from "../../helpers";
import { useAuth } from "../../context/AuthContext";
import { useMidjourneyPrompts } from "../../context/MidjourneyPromptsContext";
import MidjourneyPromptsItem from "./components/MidjourneyPromptsItem";
import PromptsModal from "./components/PromptsModal";
import { useContent } from "../../context/ContentContext";
import styles from "./MidjourneyPrompts.module.scss";
import InformationBanner from "../../components/InformationBanner";
import Pagination from "../../components/Pagination";
import PromptsLoading from "../../components/Loading/PromptsLoading";
import MidjourneyPromptsFilters from "./components/MidjourneyPromptsFilters";
import {
  fetchMidjourneyPrompts,
  getPaginationActions,
  getFiltersActions,
  getModalActions,
} from "./utils";
import FoundResults from "../../components/Filters/FoundResults";

const MidjourneyPromptsPage = () => {
  const [errors, setErrors] = useState({
    fetchingMidjourneyPrompts: false,
    noMidjourneyPrompts: false,
    filteringMidjourneyPrompts: false,
  });
  const [isLoadingMidjourneyPrompts, setIsLoadingMidjourneyPrompts] =
    useState(true);
  const [filteredMidjourneyPrompts, setFilteredMidjourneyPrompts] = useState(
    []
  );
  const [entireListOfPrompts, setEntireListOfPrompts] = useState([]);
  const [midjourneyPromptsToShow, setMidjourneyPromptsToShow] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchingValue, setSearchingValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState({
    category: "Wszystkie",
    subCategory: null,
    type: "main",
  });
  const midjourneyPromptsPerPage = 15;
  const [promptDetails, setPromptDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useAuth();
  const { content } = useContent();
  const {
    midjourneyPrompts,
    freePrompts,
    isContextErrorOccurred,
    isContextLoading,
  } = useMidjourneyPrompts();

  const paginationActions = getPaginationActions({
    setCurrentPage,
    setMidjourneyPromptsToShow,
    midjourneyPromptsPerPage,
    filteredMidjourneyPrompts,
  });

  const filterActions = getFiltersActions({
    setIsLoadingMidjourneyPrompts,
    midjourneyPrompts,
    selectedCategory,
    setFilteredMidjourneyPrompts,
    searchingValue,
    setCurrentPage,
    midjourneyPromptsPerPage,
    setMidjourneyPromptsToShow,
    setErrors,
    setSelectedCategory,
    setSearchingValue,
  });

  const modalActions = getModalActions({
    setPromptDetails,
    setIsModalOpen,
  });

  useEffect(() => {
    fetchMidjourneyPrompts(
      setIsLoadingMidjourneyPrompts,
      isContextLoading,
      isContextErrorOccurred,
      setErrors,
      user,
      midjourneyPrompts,
      freePrompts,
      setFilteredMidjourneyPrompts,
      setEntireListOfPrompts,
      midjourneyPromptsPerPage,
      setMidjourneyPromptsToShow
    );
  }, [
    user,
    midjourneyPrompts,
    freePrompts,
    isContextErrorOccurred,
    isContextLoading,
    user.membershipActive,
  ]);

  useEffect(() => {
    if (midjourneyPrompts.length > 0) {
      const uniqueCategories = getUniqueCategories(midjourneyPrompts);
      const standarizedCategories = uniqueCategories.map((el) => {
        return {
          category: el,
          subCategories: [],
        };
      });
      setCategories(standarizedCategories);
    }
  }, [midjourneyPrompts]);

  useEffect(() => {
    if (midjourneyPrompts.length > 0) {
      filterActions.handleSearchByValue();
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

  if (errors.fetchingMidjourneyPrompts) return <div>Error...</div>;

  const totalPages = Math.ceil(
    filteredMidjourneyPrompts.length / midjourneyPromptsPerPage
  );
  const visiblePageNumbers = [];
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  for (let page = startPage; page <= endPage; page++) {
    visiblePageNumbers.push(page);
  }

  if (!content) return null;

  return (
    <div className={styles.midjourneyPromptsPageWrapper}>
      {isModalOpen && (
        <PromptsModal
          promptDetails={promptDetails}
          closeModal={modalActions.closeModal}
        />
      )}
      <InformationBanner
        heading={content.midjourneyPrompts.heroHeader}
        text={content.midjourneyPrompts.heroContent}
      />
      <div className={styles.midjourneyPromptsPage}>
        <MidjourneyPromptsFilters
          handleFormSubmit={filterActions.handleFormSubmit}
          searchingValue={searchingValue}
          handleSearchingValueChange={filterActions.handleSearchingValueChange}
          isLoadingMidjourneyPrompts={isLoadingMidjourneyPrompts}
          categories={categories}
          handleCategoryChange={filterActions.handleCategoryChange}
          entireListOfPrompts={entireListOfPrompts}
          selectedCategory={selectedCategory}
          handleResetFilters={filterActions.handleResetFilters}
        />
        <div className={styles.midjourneyPromptsList}>
          {!isLoadingMidjourneyPrompts ? (
            <div className={styles.midjourneyPromptsListWrapper}>
              <FoundResults count={filteredMidjourneyPrompts.length} />
              {midjourneyPromptsToShow.map((promptToShow, index) => (
                <MidjourneyPromptsItem
                  key={index}
                  prompt={promptToShow}
                  onCopy={modalActions.handleCopyButtonClick}
                  onOpenModal={modalActions.openModal}
                />
              ))}
              {midjourneyPromptsToShow.length > 0 && (
                <Pagination
                  handlePageChange={paginationActions.handlePageChange}
                  currentPage={currentPage}
                  promptsLength={filteredMidjourneyPrompts.length}
                  itemsPerPage={midjourneyPromptsPerPage}
                />
              )}
            </div>
          ) : (
            <PromptsLoading />
          )}
        </div>
      </div>
    </div>
  );
};

export default MidjourneyPromptsPage;
