import { generateMidjourneyPrompt } from "../../services/api";
import { toast } from "react-toastify";

export const getPromptActions = ({
  textToSend,
  setTextToSend,
  setIsLoading,
  setIsCopied,
  setGeneratedPrompt,
  loadingNotificationId,
  generatedPrompt,
}) => {
  const handleGeneratePrompt = async () => {
    if (!textToSend) {
      toast.warn("Proszę wpisać tekst przed generowaniem prompta.");
      return;
    }

    setIsLoading(true);
    setIsCopied(false);
    setGeneratedPrompt("");

    loadingNotificationId.current = toast.info("Generowanie w toku...", {
      autoClose: false,
    });

    try {
      const response = await generateMidjourneyPrompt(textToSend);
      setGeneratedPrompt(response);
      toast.success("Prompt został wygenerowany pomyślnie!");
    } catch (error) {
      console.error("Error generating prompt:", error);
      toast.error("Wystąpił błąd podczas generowania prompta.");
    } finally {
      setIsLoading(false);
      if (loadingNotificationId.current) {
        toast.dismiss(loadingNotificationId.current);
      }
    }
  };

  const handleCopyGeneratedPrompt = () => {
    if (!generatedPrompt) {
      toast.warn("Brak wygenerowanego prompta do skopiowania.");
      return;
    }

    navigator.clipboard.writeText(`${generatedPrompt}`);

    setIsCopied(true);
    toast.info("Prompt został skopiowany!");

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const handleTextChange = (event) => {
    setTextToSend(event.target.value);
  };

  return {
    handleGeneratePrompt,
    handleCopyGeneratedPrompt,
    handleTextChange,
  };
};
