import { toast } from "react-toastify";
import {
  setErrorTrue,
  filterOwnPromptsByCategory,
  loadNumberOfPrompts,
  escapeRegExp,
} from "../../helpers";

import {
  getUserPrompts,
  addPromptToUser,
  deleteUserPrompt,
  editUserPrompt,
} from "../../services/api";

export const promptsPerPage = 15;

export const getPaginationActions = ({
  setCurrentPage,
  filteredPrompts,
  setPromptsToShow,
}) => {
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const start = (page - 1) * promptsPerPage;
    const end = start + promptsPerPage;
    setPromptsToShow(filteredPrompts.slice(start, end));
  };

  return { handlePageChange };
};

export const getFiltersActions = ({
  prompts,
  setIsLoadingPrompts,
  selectedCategory,
  searchingValue,
  setFilteredPrompts,
  setCurrentPage,
  setErrors,
  setPromptsToShow,
  setSearchingValue,
  setSelectedCategory,
}) => {
  const handleSearchByValue = async (updatedPrompts) => {
    setIsLoadingPrompts(true);

    const filteredPromptsByCategory = filterOwnPromptsByCategory(
      updatedPrompts,
      selectedCategory.category
    );

    const filteredPrompts = filterPromptsBySearch(
      filteredPromptsByCategory,
      searchingValue
    );

    setFilteredPrompts(filteredPrompts);
    setCurrentPage(1);

    const promptsToShow = loadNumberOfPrompts(filteredPrompts, promptsPerPage);

    const isAnyPromptToShow = promptsToShow.length > 0;
    if (!isAnyPromptToShow) {
      setFilteredPrompts([]);
      setPromptsToShow([]);
      setIsLoadingPrompts(false);
      if (filteredPromptsByCategory.length > 0) {
        toast.info("Brak promptów spełniających kryteria wyszukiwania.");
      } else {
        toast.info("Brak promptów w wybranej kategorii.");
      }
      setErrorTrue("noPrompts", setErrors);
      return;
    }

    setPromptsToShow(promptsToShow);

    setIsLoadingPrompts(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    handleSearchByValue(prompts);
  };

  const handleSearchingValueChange = (ev) => {
    setSearchingValue(ev.target.value);
  };

  const handleCategoryChange = (newSelection) => {
    if (selectedCategory.category === newSelection) {
      setSelectedCategory({ category: "Wszystkie" });
    } else {
      setSelectedCategory({ category: newSelection });
    }
  };

  const handleResetFilters = () => {
    setSelectedCategory({ category: "Wszystkie" });
    setSearchingValue("");
    toast.success("Filtry zostały zresetowane");
  };

  return {
    handleSearchByValue,
    handleFormSubmit,
    handleSearchingValueChange,
    handleCategoryChange,
    handleResetFilters,
  };
};

export const getModalActions = ({
  setModalData,
  promptToRemoveId,
  prompts,
  setPrompts,
  filtersActions,
  setIsRemoveModalOpen,
}) => {
  const openModal = (prompt) => {
    setModalData({
      isOpen: true,
      prompt: prompt.content,
      category: prompt.category,
      id: prompt.id,
    });
  };

  const closeModal = () => {
    setModalData({
      isOpen: false,
      prompt: "",
      category: "",
      id: "",
    });
  };

  const confirmRemoveFromUser = async () => {
    try {
      await deleteUserPrompt(promptToRemoveId);

      const updatedPrompts = prompts.filter(
        (prompt) => prompt.id !== promptToRemoveId
      );

      setPrompts(updatedPrompts);
      filtersActions.handleSearchByValue(updatedPrompts);

      setIsRemoveModalOpen(false);
      toast.success("Prompt został usunięty!");
    } catch (error) {
      console.error("Error removing from user:", error);
      toast.error("Wystąpił błąd podczas usuwania prompta.");
    }
  };

  return { openModal, closeModal, confirmRemoveFromUser };
};

export const getPromptsActions = ({
  prompts,
  setPrompts,
  filtersActions,
  modalActions,
  setIsLoadingPrompts,
  setErrors,
  setFilteredPrompts,
  setPromptsToShow,
  newPrompt,
  setNewPrompt,
  setPromptToRemoveId,
  setIsRemoveModalOpen,
}) => {
  const handleEditUserPrompt = async (promptId, content, category) => {
    try {
      await editUserPrompt(promptId, content, category);

      const updatedPrompts = prompts.map((prompt) => {
        if (prompt.id === promptId) {
          return { ...prompt, content, category };
        }
        return prompt;
      });

      setPrompts(updatedPrompts);

      filtersActions.handleSearchByValue(updatedPrompts);

      toast.success("Prompt został zmodyfikowany!");
      modalActions.closeModal();
    } catch (error) {
      console.error("Error adding to user:", error);
      toast.error("Wystąpił błąd podczas edycji prompta!");
    }
  };

  const fetchPrompts = async () => {
    try {
      const { prompts, isErrorOccured } = await getUserPrompts();

      if (isErrorOccured) {
        setIsLoadingPrompts(false);
        toast.error("Wystąpił błąd podczas ładowania promptów.");
        setErrorTrue("fetchingPrompts", setErrors);
        return;
      }

      const isAnyPromptAdded = prompts.length > 0;
      if (!isAnyPromptAdded) {
        setIsLoadingPrompts(false);
        toast.info("Brak dostępnych promptów.");
        setErrorTrue("noPrompts", setErrors);
        return;
      }

      setFilteredPrompts(prompts);
      setPrompts(prompts);

      const promptsToShow = prompts.slice(0, promptsPerPage);
      setPromptsToShow(promptsToShow);

      setIsLoadingPrompts(false);
    } catch (error) {
      console.error("Error fetching prompts:", error);
      toast.error("Wystąpił błąd podczas ładowania promptów.");
    }
  };

  const handleAddNewPrompt = async () => {
    try {
      await addPromptToUser(newPrompt, "Bez kategorii");
      await fetchPrompts();

      setNewPrompt("");
      toast.success("Prompt został zapisany!");
    } catch (error) {
      console.error("Error adding to user:", error);
      toast.error("Wystąpił błąd podczas zapisywania prompta.");
    }
  };

  const handleNewPromptChange = (event) => {
    setNewPrompt(event.target.value);
  };

  const removeFromUser = (promptId) => {
    setPromptToRemoveId(promptId);
    setIsRemoveModalOpen(true);
  };

  return {
    handleEditUserPrompt,
    fetchPrompts,
    handleAddNewPrompt,
    handleNewPromptChange,
    removeFromUser,
  };
};

export const getUniqueCategories = (prompts) => {
  const uniqueCategories = [
    ...new Set(prompts.map((prompt) => prompt.category)),
  ];
  uniqueCategories.sort();
  return uniqueCategories;
};

export const filterPromptsBySearch = (prompts, searchValue) => {
  if (!searchValue || typeof searchValue !== "string") {
    return prompts;
  }
  const formattedSearchValue = escapeRegExp(searchValue.toLowerCase().trim());
  const keywords = formattedSearchValue
    .split(" ")
    .map((keyword) => new RegExp(keyword, "i"));
  return prompts.filter((prompt) =>
    keywords.every((keyword) => keyword.test(prompt.content))
  );
};

export const handleCopyButtonClick = (promptToCopy) => {
  const settingsLanguage = localStorage.getItem("responseLanguage") || "polski";

  navigator.clipboard.writeText(
    `${promptToCopy}\n\nOdpowiedz przygotuj w języku ${settingsLanguage}`
  );

  toast.success("Prompt został skopiowany do schowka!");
};
