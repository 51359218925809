import { escapeRegExp } from "./escapeRegExp";

export const getUniqueCategoriesWithSubcategories = (prompts) => {
  const businessCategories = {};
  const normalCategories = {};

  prompts.forEach((prompt) => {
    const { category, business, subCategory } = prompt;

    if (business) {
      if (!businessCategories[category]) {
        businessCategories[category] = new Set();
      }
      businessCategories[category].add(subCategory);
    } else {
      if (!normalCategories[category]) {
        normalCategories[category] = new Set();
      }
      normalCategories[category].add(subCategory);
    }
  });

  const sortedBusinessCategories = Object.keys(businessCategories).sort();
  const sortedNormalCategories = Object.keys(normalCategories).sort();

  const uniqueCategories = sortedBusinessCategories
    .concat(sortedNormalCategories)
    .map((category) => ({
      category,
      subCategories: businessCategories[category]
        ? Array.from(businessCategories[category]).filter(Boolean).sort()
        : Array.from(normalCategories[category]).filter(Boolean).sort(),
    }));

  // we must have "Pozostałe" as the last category in the array
  uniqueCategories.sort((a, b) => {
    if (a.category === "Pozostałe") return 1;
    if (b.category === "Pozostałe") return -1;
    return 0;
  });

  return uniqueCategories;
};

export const getUniqueCategories = (prompts) => {
  const uniqueCategories = [];
  const businessCategories = [];
  const normalCategories = [];

  prompts.forEach((prompt) => {
    const { category, business } = prompt;
    if (!businessCategories.includes(category) && business) {
      businessCategories.push(category);
    } else if (!normalCategories.includes(category) && !business) {
      normalCategories.push(category);
    }
  });

  businessCategories.sort();
  normalCategories.sort();
  uniqueCategories.push(...businessCategories, ...normalCategories);
  return uniqueCategories;
};

export const filterPromptsByCategory = (prompts, selectedCategory) => {
  const { category, subCategory, type } = selectedCategory;

  if (category === "Wszystkie") {
    return prompts;
  }

  if (type === "subcategory") {
    return prompts.filter(
      (prompt) =>
        prompt.category === category && prompt.subCategory === subCategory
    );
  }

  return prompts.filter((prompt) => prompt.category === category);
};

export const filterPromptsBySearch = (
  prompts,
  searchValue,
  midjourney = false
) => {
  if (!searchValue || typeof searchValue !== "string") {
    return prompts;
  }
  const formattedSearchValue = escapeRegExp(searchValue.toLowerCase().trim());
  const keywords = formattedSearchValue
    .split(" ")
    .map((keyword) => new RegExp(keyword, "i"));

  return prompts.filter((prompt) => {
    const textToSearch = midjourney ? prompt.main_description : prompt.prompt;
    return keywords.every((keyword) => keyword.test(textToSearch));
  });
};

export const loadNumberOfPrompts = (listOfPrompts, numberOfPromptsToShow) => {
  return listOfPrompts.slice(0, numberOfPromptsToShow);
};

export const filterOwnPromptsByCategory = (prompts, selectedCategory) => {
  if (selectedCategory === "Wszystkie") {
    return prompts;
  }

  return prompts.filter((prompt) => prompt.category === selectedCategory);
};
