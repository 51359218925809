import React, { createContext, useState, useEffect, useContext } from "react";
import { getContent } from "../services/api";

const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    getContent().then((response) => {
      if (!response.isErrorOccured) {
        setContent(response.content);
      }
    });
  }, []);

  return (
    <ContentContext.Provider value={{ content }}>
      {children}
    </ContentContext.Provider>
  );
};

export const useContent = () => {
  const context = useContext(ContentContext);
  if (!context) {
    throw new Error("useContent must be used within a ContentProvider");
  }
  return context;
};
