import React, { useState } from "react";
import styles from "./PromptsModal.module.scss";
import { useNavigate } from "react-router-dom";
import CopyIcon from "../../../../assets/CopyIcon";
import CogIcon from "../../../../assets/CogIcon";

import {
  preparePromptWithLabels,
  getModalActions,
  startEditing,
} from "./utils";

const PromptsModal = ({ promptDetails, closeModal }) => {
  const [editedPromptData, setEditedPromptData] = useState({
    isOpen: false,
    variables: [],
    basePrompt: "",
    modifiedPrompt: "",
  });

  const navigate = useNavigate();

  const preparedMainPrompt = preparePromptWithLabels(
    promptDetails.prompt,
    promptDetails.main_variables
  );
  const preparedExperimentalPrompt = preparePromptWithLabels(
    promptDetails.experimental_prompt,
    promptDetails.experimental_variables
  );

  const modalActions = getModalActions({
    editedPromptData,
    setEditedPromptData,
    closeModal,
    navigate,
  });

  return (
    <div
      className={styles.modalWrapper}
      onClick={modalActions.handleModalClick}
    >
      <div className={styles.modal}>
        <div className={styles.promptImage}>
          <img src={promptDetails.imgUrl} alt="Big prompt" />
        </div>
        {editedPromptData.isOpen ? (
          <div className={styles.layoutContent}>
            <div className={styles.smallPromptImage}>
              <img src={promptDetails.imgUrl} alt="Small prompt" />
            </div>
            <h1>{promptDetails.title}</h1>
            <div className={styles.divider} />
            <p className={styles.text}>{promptDetails.main_description}</p>
            <p className={styles.heading}>Dostosuj prompt</p>
            <div className={styles.promptWrapper}>
              <div className={styles.copyElement}>
                {editedPromptData.modifiedPrompt}
              </div>
            </div>
            <div className={styles.variablesWrapper}>
              {editedPromptData.variables.map((variable, index) => (
                <div key={index} className={styles.variables}>
                  <label>{variable.label}:</label>
                  <input
                    type="text"
                    value={variable.value || ""}
                    onChange={(e) =>
                      modalActions.handleVariableInputChange(
                        variable.label,
                        e.target.value
                      )
                    }
                  />
                </div>
              ))}
            </div>
            <div className={styles.buttons}>
              <div className={styles.buttonsWrapper}>
                <button onClick={modalActions.handleEnhancePrompt}>
                  Ulepsz w promptfixerze
                </button>
                <button
                  onClick={modalActions.copyModifiedPrompt}
                  className={styles.full}
                >
                  Skopiuj prompt
                </button>
              </div>
              <button
                className={styles.closeButton}
                onClick={modalActions.closeEditing}
              >
                Zakończ edycję
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.layoutContent}>
            <div className={styles.smallPromptImage}>
              <img src={promptDetails.imgUrl} alt="Small prompt" />
            </div>
            <h1>{promptDetails.title}</h1>
            <div className={styles.divider} />
            <p className={styles.text}>{promptDetails.main_description}</p>
            <p className={styles.text}>
              Możesz skopiować te polecenia i użyć ich w modelu{" "}
              <a
                href="https://www.midjourney.com/"
                target="_blank"
                rel="noreferrer"
              >
                Midjourney
              </a>
              .
            </p>
            <p className={styles.text}>
              Aby wykorzystać te gotowe prompty, zastąp elementy znajdujące się
              w nawiasach kwadratowych [[]] własną treścią. Podane szablony mają
              jedynie charakter inspiracji. Twórz własne projekty i dziel się
              nimi ze społecznością.
            </p>
            <p className={styles.heading}>Najlepszy prompt</p>
            <div
              className={styles.editPrompt}
              onClick={() =>
                startEditing(preparedMainPrompt, setEditedPromptData)
              }
            >
              <CogIcon />
              Edytuj
            </div>
            <div className={styles.promptWrapper}>
              <div className={styles.copyElement}>
                {preparedMainPrompt}
                <div
                  className={styles.copyButton}
                  onClick={() =>
                    modalActions.copyOriginalPrompt(preparedMainPrompt)
                  }
                >
                  <CopyIcon />
                  Kopiuj
                </div>
              </div>
            </div>
            <div className={styles.divider} />
            <p className={styles.text}>
              {promptDetails.experimental_description}
            </p>
            <div
              className={styles.editPrompt}
              onClick={() =>
                startEditing(preparedExperimentalPrompt, setEditedPromptData)
              }
            >
              <CogIcon />
              Edytuj
            </div>
            <div className={styles.promptWrapper}>
              <div className={styles.copyElement}>
                {preparedExperimentalPrompt}
                <div
                  className={styles.copyButton}
                  onClick={() =>
                    modalActions.copyOriginalPrompt(preparedExperimentalPrompt)
                  }
                >
                  <CopyIcon />
                  Kopiuj
                </div>
              </div>
            </div>
            {promptDetails.suggestions.length > 0 && (
              <div className={styles.suggestionsWrapper}>
                <div className={styles.divider} />
                <p className={styles.text}>
                  Jeżeli masz pomysł na coś konkretnego, możesz zasugerować
                  modelowi by użył konkretnych inspiracji.
                </p>
                <div className={styles.suggestions}>
                  {promptDetails.suggestions.map((suggestion, index) => {
                    const preparedPrompt = preparePromptWithLabels(
                      suggestion.prompt,
                      suggestion.variables
                    );

                    return (
                      <div className={styles.suggestion} key={index}>
                        <p className={styles.text}>{suggestion.description}</p>
                        <div className={styles.suggestionPromptWrapper}>
                          <div
                            className={styles.editPrompt}
                            onClick={() =>
                              startEditing(preparedPrompt, setEditedPromptData)
                            }
                          >
                            <CogIcon />
                            Edytuj
                          </div>
                          <div className={styles.promptWrapper}>
                            <div className={styles.copyElement}>
                              {preparedPrompt}
                              <div
                                className={styles.copyButton}
                                onClick={() =>
                                  modalActions.copyOriginalPrompt(
                                    preparedPrompt
                                  )
                                }
                              >
                                <CopyIcon />
                                Kopiuj
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PromptsModal;
