import styles from "./AppLoading.module.scss";

const AppLoading = () => {
  return (
    <div className={styles.wrapper}>
      <img src="logo.png" alt="logo-loader" className={styles.logo} />
      <div
        className={styles.bouncingLoaderWrapper}
        style={{ justifyContent: "center" }}
      >
        {[...Array(3)].map((_, index) => (
          <div className={styles.loaderDot} key={index} />
        ))}
      </div>
    </div>
  );
};

export default AppLoading;
