import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppLoading from "../../components/Loading/AppLoading";
import { useAuth } from "../../context/AuthContext";
import {
  getTokenFromCookies,
  parseJwt,
  removeTokens,
} from "../../services/cookies/tokenCookies";

const NotRequireAuth = ({ children }) => {
  const [isChecking, setIsChecking] = useState(true);
  const navigate = useNavigate();

  const { user } = useAuth();

  /** We are checking if the token has not expired. If it is still valid, we redirect to "/",
   * and if it has expired, we render the login page.  */
  useEffect(() => {
    const token = getTokenFromCookies();

    if (token && !user) {
      const decodedToken = parseJwt(token);

      if (!decodedToken) {
        removeTokens();
        setIsChecking(false);
        navigate("/login");
        return;
      }
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const isTokenExpired =
        decodedToken.exp && decodedToken.exp < currentTimestamp;

      if (!isTokenExpired) {
        navigate("/");
      }
    }

    setIsChecking(false);
  }, [user, navigate]);

  if (isChecking) return <AppLoading />;

  return <>{children}</>;
};

export default NotRequireAuth;
