import styles from "./DefaultSpinner.module.scss";

const DefaultSpinner = () => {
  return (
    <div className={styles.buttonSpinnerWrapper}>
      <svg
        className={styles.buttonStyledSpinner}
        viewBox="0 0 50 50"
        width={50}
        height={50}
      >
        <circle
          className={styles.path}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

export default DefaultSpinner;
