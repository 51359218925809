import React, { createContext, useState, useContext, useCallback } from "react";
import { getUserLoginWithCode } from "../services/auth";
const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateUser = useCallback((userData) => {
    setUser(userData);
  }, []);

  const loginWithCode = useCallback(async (code, state, hmac) => {
    setIsLoading(true);
    const user = await getUserLoginWithCode(code, state, hmac);
    if (user) {
      setUser(user);
    }
    setIsLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        loginWithCode,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
