import CryptoJS from "crypto-js";

//to funkcje generujące HMAC i state
export const generateHmac = (message, key) => {
  return CryptoJS.HmacSHA256(message, key).toString(CryptoJS.enc.Hex);
};

export const generateState = () => {
  const randomBytes = Array.from(crypto.getRandomValues(new Uint8Array(24)))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  const timestamp = Math.floor(Date.now() / 1000).toString();
  return `${randomBytes}|${timestamp}`;
};
