import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import DangerIcon from "../../../../assets/DangerIcon";
import HeartIcon from "../../../../assets/HeartIcon";
import styles from "./RenderUserButton.module.scss";
import ConfirmationModal from "../../../ConfirmationModal";
import { logOut } from "../../../../services/auth";

const RenderUserButton = ({ user }) => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("responseLanguage") || "polish"
  );

  const userButtonRef = useRef(null);
  const userInfoBoxRef = useRef(null);

  const toggleUserInfo = useCallback(() => {
    setShowUserInfo((prevShowUserInfo) => !prevShowUserInfo);
  }, []);

  const toggleLogoutConfirmation = () => {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  };

  const handleLogout = () => {
    toggleLogoutConfirmation();
    toggleUserInfo();
    logOut();
    // navigate("/login");
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem("responseLanguage", selectedLanguage);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userButtonRef.current &&
        !userButtonRef.current.contains(event.target) &&
        userInfoBoxRef.current &&
        !userInfoBoxRef.current.contains(event.target) &&
        showUserInfo
      ) {
        toggleUserInfo();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showUserInfo, toggleUserInfo]);

  if (user) {
    const shortenedUsername =
      user.username.length > 30
        ? `${user.username.slice(0, 30)}...`
        : user.username;
    return (
      <>
        <img
          src={user.avatar}
          alt="User Logo"
          className={styles.accountButton}
          onClick={toggleUserInfo}
          ref={userButtonRef}
        />
        <div
          className={`${styles.userInfoBox} ${
            showUserInfo ? styles.showUserInfo : ""
          }`}
          ref={userInfoBoxRef}
        >
          <div className={styles.userInfoBoxWrapper}>
            <img
              src={user.avatar}
              alt="User Logo"
              className={styles.accountButton}
            />
            <div className={styles.userInfoBoxHeader}>
              <p title={user.username}>{shortenedUsername}</p>
              <p className={styles.userMembership}>
                {user.membershipActive ? (
                  <>
                    <HeartIcon color="#fff" /> Twoje członkostwo jest ważne!
                  </>
                ) : (
                  <>
                    <DangerIcon /> Twoje członkostwo nie jest ważne!
                  </>
                )}
              </p>
            </div>
          </div>
          <div className={styles.languageSelector}>
            <label htmlFor="language">Język odpowiedzi:</label>
            <select
              id="language"
              name="language"
              value={language}
              onChange={handleLanguageChange}
            >
              <option value="polish">Polski</option>
              <option value="english">Angielski</option>
              <option value="spanish">Hiszpański</option>
            </select>
          </div>
          <div className={styles.buttons}>
            <a
              href="https://app.campusai.pl/course/59/kurs-generowania-tekstu-metawarstwy"
              target="_blank"
              rel="noreferrer"
              className={styles.button}
              style={{ borderColor: "#E3E3E7" }}
            >
              <lord-icon
                src="https://cdn.lordicon.com/uecgmesg.json"
                trigger="hover"
                style={{ width: "60px", height: "60px" }}
              ></lord-icon>
              <span>Kurs Metawarstwy</span>
            </a>
            <a
              href="https://app.campusai.pl/"
              className={styles.button}
              style={{ borderColor: "#E3E3E7" }}
            >
              <lord-icon
                src="https://cdn.lordicon.com/jdsvypqr.json"
                trigger="hover"
                style={{ width: "60px", height: "60px" }}
              ></lord-icon>
              <span>Powrót do CampusAI</span>
            </a>
            <div className={styles.button} onClick={toggleLogoutConfirmation}>
              <lord-icon
                src="https://cdn.lordicon.com/jrkpfcoz.json"
                trigger="hover"
                style={{ width: "60px", height: "60px" }}
              ></lord-icon>
              <span>Wyloguj się</span>
            </div>
          </div>
        </div>
        <ConfirmationModal
          modalData={{
            message: "Czy na pewno chcesz się wylogować?",
            confirmText: "Wyloguj",
            cancelText: "Anuluj",
          }}
          isOpen={showLogoutConfirmation}
          onConfirm={handleLogout}
          onClose={toggleLogoutConfirmation}
        />
      </>
    );
  } else {
    return (
      <Link to="/login" className={styles.accountButton}>
        Zaloguj
      </Link>
    );
  }
};

export default RenderUserButton;
