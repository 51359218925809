import * as Sentry from "@sentry/react";
import axios from "axios";

export const handleSentryError = (error, actionName) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) {
      return;
    }
    const status = error.response?.status || "Unknown status";
    const message = error.response?.data.message ?? error.response?.statusText;

    Sentry.captureMessage(
      `${actionName} error: ${status} - ${message}`,
      "error"
    );
  } else {
    Sentry.captureMessage(`${actionName} error: ${error}`, "error");
  }
};
