import React, { useEffect, useState } from "react";
import styles from "./UserPromptsModal.module.scss";
import CloseIcon from "../../../../assets/CloseIcon";

const UserPromptsModal = ({ modalData, onSave, onClose, onDelete }) => {
  const [promptText, setPromptText] = useState("");
  const [category, setCategory] = useState("");

  const handleSave = () => {
    if (promptText.trim() === "") {
      onDelete(modalData.id);
    } else {
      onSave(modalData.id, promptText, category);
    }
  };

  useEffect(() => {
    setPromptText(modalData.prompt);
    setCategory(modalData.category);
  }, [modalData]);

  return (
    <div className={styles.modalWrapper} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <h2>Edytuj Prompt</h2>
        <input
          type="text"
          placeholder="Treść prompta..."
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
          style={{ width: "100%" }}
        />
        <div className={styles.categoryEdit}>
          <p>Kategoria:</p>
          <input
            type="text"
            placeholder="Kategoria"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>
        <div className={styles.buttons}>
          <button onClick={handleSave}>Zapisz prompt</button>
        </div>
        <div className={styles.closeButton} onClick={onClose}>
          <CloseIcon color="#181c32" />
        </div>
      </div>
    </div>
  );
};

export default UserPromptsModal;
