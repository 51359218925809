import { useState } from "react";
import styles from "./CategoryAccordion.module.scss";

const CategoryAccordion = ({
  categories,
  handleCategoryChange,
  prompts,
  selectedCategory,
  setSelectedCategory,
  withSubs,
}) => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  const { category, subCategory } = selectedCategory;

  const toggleCategory = (category) => {
    if (expandedCategory === category) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(category);
    }
  };

  return (
    <div className={styles["category-accordion"]}>
      {categories.map((categoryObj, index) => (
        <div key={index} className={styles["accordion-item"]}>
          <div
            className={`${styles["accordion-header"]} ${
              (category === categoryObj.category ||
                category === categoryObj.subCategory) &&
              styles["mobile-menu-active"]
            }`}
            onClick={() => {
              toggleCategory(categoryObj.category);
              handleCategoryChange(
                {
                  category: categoryObj.category,
                  type: "main",
                },
                setSelectedCategory
              ); // Tutaj wywołujemy drugą funkcję onClick
            }}
          >
            {categoryObj.category} (
            {
              prompts.filter(
                (prompt) => prompt.category === categoryObj.category
              ).length
            }
            )
          </div>
          {withSubs ? (
            <>
              {expandedCategory === categoryObj.category && (
                <div className={styles["accordion-content"]}>
                  <ul>
                    {categoryObj.subCategories.map((subcategory, subIndex) => (
                      <li
                        className={
                          subCategory === subcategory
                            ? styles["mobile-menu-active"]
                            : ""
                        }
                        key={subIndex}
                        onClick={() =>
                          handleCategoryChange({
                            category: categoryObj.category,
                            subCategory: subcategory,
                            type: "subcategory",
                          })
                        }
                      >
                        {subcategory}(
                        {
                          prompts.filter(
                            (prompt) =>
                              prompt.category === categoryObj.category &&
                              prompt.subCategory === subcategory
                          ).length
                        }
                        )
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default CategoryAccordion;
