import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./Menu.module.scss";
import { useAuth } from "../../context/AuthContext";
import RenderUserButton from "./components/RenderUserButton";

const Menu = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [toolsDropdownOpen, setToolsDropdownOpen] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const toolsDropdownRef = useRef(null);

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleToolsDropdownToggle = () => {
    setToolsDropdownOpen(!toolsDropdownOpen);
  };

  useEffect(() => {
    if (
      mobileMenuOpen === true &&
      (location.pathname === "/prompt-fixer" ||
        location.pathname === "/generate-midjourney-prompt")
    ) {
      setToolsDropdownOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileMenuOpen]);

  useEffect(() => {
    setMobileMenuOpen(false);
    setToolsDropdownOpen(false);
  }, [location]);

  useEffect(() => {
    const closeDropdownOnOutsideClick = (event) => {
      if (
        toolsDropdownRef.current &&
        !toolsDropdownRef.current.contains(event.target) &&
        !mobileMenuOpen
      ) {
        setToolsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", closeDropdownOnOutsideClick);

    return () => {
      document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.menu}>
      <img
        src="logo-header.svg"
        alt="Logo"
        className={styles.menuLogo}
        onClick={handleLogoClick}
      />
      <div
        className={styles.menuLeft}
        style={{ display: `${mobileMenuOpen ? "flex" : "none"}` }}
      >
        <Link
          to="/"
          className={`${styles.menuLink} ${
            location.pathname === "/" ? styles.activeLink : ""
          }`}
        >
          Lista Promptów
        </Link>
        <Link
          to="/lista-promptow-midjourney"
          className={`${styles.menuLink} ${
            location.pathname === "/lista-promptow-midjourney"
              ? styles.activeLink
              : ""
          }`}
        >
          Lista Promptów Midjourney
        </Link>
        <div className={styles.toolsDropdown} ref={toolsDropdownRef}>
          <button
            onClick={handleToolsDropdownToggle}
            className={`${styles.menuLink}`}
          >
            Narzędzia
          </button>
          {toolsDropdownOpen && (
            <div className={styles.dropdownContent}>
              <Link
                to="/prompt-fixer"
                className={`${styles.menuLink} ${styles.sublink} ${
                  location.pathname === "/prompt-fixer" ? styles.activeLink : ""
                }`}
              >
                Prompt Fixer
              </Link>
              <Link
                to="/generate-midjourney-prompt"
                className={`${styles.menuLink} ${styles.sublink} ${
                  location.pathname === "/generate-midjourney-prompt"
                    ? styles.activeLink
                    : ""
                }`}
              >
                Wygeneruj prompt Midjourney
              </Link>
            </div>
          )}
        </div>
        <Link
          to="/ulubione"
          className={`${styles.menuLink} ${
            location.pathname === "/ulubione" ? styles.activeLink : ""
          }`}
        >
          Ulubione
        </Link>
        <Link
          to="/wlasne-prompty"
          className={`${styles.menuLink} ${
            location.pathname === "/wlasne-prompty" ? styles.activeLink : ""
          } sublink`}
        >
          Własne Prompty
        </Link>
      </div>
      <div className={styles.mobileMenuIcon} onClick={handleMobileMenuToggle}>
        <div className={mobileMenuOpen ? styles.open : ""}></div>
        <div className={mobileMenuOpen ? styles.open : ""}></div>
        <div className={mobileMenuOpen ? styles.open : ""}></div>
      </div>
      <div className={styles.menuRight}>
        <RenderUserButton user={user} />
      </div>
    </div>
  );
};

export default Menu;
