import { toast } from "react-toastify";
import {
  setErrorTrue,
  filterPromptsByCategory,
  filterPromptsBySearch,
  loadNumberOfPrompts,
  extractTextBetweenSquareBrackets,
} from "../../helpers";

import {
  addPromptToFavorites,
  removePromptFromFavorites,
} from "../../services/api";

export const promptsPerPage = 15;

export const getPaginationActions = ({
  setCurrentPage,
  filteredPrompts,
  setPromptsToShow,
}) => {
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const start = (page - 1) * promptsPerPage;
    const end = start + promptsPerPage;
    setPromptsToShow(filteredPrompts.slice(start, end));
  };

  return { handlePageChange };
};

export const getFiltersActions = ({
  setIsLoadingPrompts,
  prompts,
  selectedCategory,
  searchingValue,
  setFilteredPrompts,
  setCurrentPage,
  setPromptsToShow,
  setErrors,
  setSearchingValue,
  setSelectedCategory,
}) => {
  const handleSearchByValue = async () => {
    setIsLoadingPrompts(true);

    const filteredPromptsByCategory = filterPromptsByCategory(
      prompts,
      selectedCategory
    );

    const filteredPrompts = filterPromptsBySearch(
      filteredPromptsByCategory,
      searchingValue
    );

    setFilteredPrompts(filteredPrompts);
    setCurrentPage(1);

    const promptsToShow = loadNumberOfPrompts(filteredPrompts, promptsPerPage);

    const isAnyPromptToShow = promptsToShow.length > 0;
    if (!isAnyPromptToShow) {
      setFilteredPrompts([]);
      setPromptsToShow([]);
      setIsLoadingPrompts(false);
      if (filteredPromptsByCategory.length > 0) {
        toast.info("Brak promptów spełniających kryteria wyszukiwania.");
      } else {
        toast.info("Brak promptów w wybranej kategorii.");
      }
      setErrorTrue("noPrompts", setErrors);
      return;
    }

    setPromptsToShow(promptsToShow);

    setIsLoadingPrompts(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    handleSearchByValue();
  };

  const handleSearchingValueChange = (ev) => {
    setSearchingValue(ev.target.value);
  };

  const handleCategoryChange = (newSelection) => {
    setSelectedCategory(newSelection);
  };

  const handleResetFilters = () => {
    setSelectedCategory({
      category: "Wszystkie",
      subCategory: null,
      type: "main",
    });
    setSearchingValue("");
    toast.success("Filtry zostały zresetowane");
  };

  return {
    handleSearchByValue,
    handleFormSubmit,
    handleSearchingValueChange,
    handleCategoryChange,
    handleResetFilters,
  };
};

export const getPromptsActions = ({
  updateFavoritePrompts,
  setPromptToRemoveId,
  setIsRemoveModalOpen,
}) => {
  const handleAddToFavorites = async (promptId) => {
    try {
      await addPromptToFavorites(promptId, updateFavoritePrompts);
      toast.success("Prompt został dodany do ulubionych!");
    } catch (error) {
      console.error("Error adding to favorites:", error);
      toast.error("Wystąpił błąd podczas dodawania do ulubionych.");
    }
  };

  const removeFromFavorites = (promptId) => {
    setPromptToRemoveId(promptId);
    setIsRemoveModalOpen(true);
  };

  return { handleAddToFavorites, removeFromFavorites };
};

export const getModalActions = ({
  modalData,
  setModalData,
  user,
  updateFavoritePrompts,
  setIsRemoveModalOpen,
  promptToRemoveId,
}) => {
  const openModal = (prompt) => {
    const extractedTextArray = extractTextBetweenSquareBrackets(prompt);

    setModalData({
      isOpen: true,
      variables: extractedTextArray,
      basePrompt: prompt,
      modifiedPrompt: prompt,
    });
  };

  const closeModal = () => {
    setModalData({
      isOpen: false,
      variables: [],
      modifiedPrompt: "",
    });
  };

  const handleVariableInputChange = (variableName, value, index) => {
    const updatedModalData = { ...modalData };

    updatedModalData.variables[index].value = value;

    updatedModalData.modifiedPrompt = updatedModalData.basePrompt;

    updatedModalData.variables.forEach((variable) => {
      const variablePattern = new RegExp(
        variable.label.replace(/[[\]]/g, "\\$&"),
        "g"
      );

      updatedModalData.modifiedPrompt = updatedModalData.modifiedPrompt.replace(
        variablePattern,
        variable.value || variable.label
      );
    });

    setModalData(updatedModalData);
  };

  const confirmRemoveFromFavorites = async () => {
    try {
      await removePromptFromFavorites(promptToRemoveId, updateFavoritePrompts);
      setIsRemoveModalOpen(false);
      toast.success("Prompt został usunięty z ulubionych!");
    } catch (error) {
      console.error("Error removing from favorites:", error);
      toast.error("Wystąpił błąd podczas usuwania z ulubionych.");
    }
  };

  return {
    openModal,
    handleVariableInputChange,
    closeModal,
    confirmRemoveFromFavorites,
  };
};

export const copyModifiedPrompt = ({ modalData }) => {
  const settingsLanguage = localStorage.getItem("responseLanguage") || "polski";

  navigator.clipboard.writeText(
    `${modalData.modifiedPrompt}\n\nOdpowiedz przygotuj w języku ${settingsLanguage}`
  );

  toast.success("Prompt został skopiowany do schowka!");
};

export const handleCopyButtonClick = (promptToCopy) => {
  const settingsLanguage = localStorage.getItem("responseLanguage") || "polski";

  navigator.clipboard.writeText(
    `${promptToCopy}\n\nOdpowiedz przygotuj w języku ${settingsLanguage}`
  );

  toast.success("Prompt został skopiowany do schowka!");
};

export const fetchPrompts = async (
  isContextLoading,
  setIsLoadingPrompts,
  isContextErrorOccurred,
  setErrors,
  user,
  prompts,
  freePrompts,
  setFilteredPrompts,
  promptsPerPage,
  setPromptsToShow
) => {
  if (isContextLoading) {
    return setIsLoadingPrompts(true);
  }
  if (isContextErrorOccurred) {
    setIsLoadingPrompts(false);
    setErrorTrue("fetchingPrompts", setErrors);
    toast.error("Wystąpił błąd podczas ładowania promptów.");
    return;
  }

  let promptsToSet;

  if (user.membershipActive) {
    promptsToSet = prompts;
  } else {
    promptsToSet = freePrompts;
  }

  const isAnyPromptAdded = promptsToSet.length > 0;
  if (!isAnyPromptAdded) {
    setIsLoadingPrompts(false);
    toast.info("Brak dostępnych promptów.");
    setErrorTrue("noPrompts", setErrors);
    return;
  }

  setFilteredPrompts(promptsToSet);

  const promptsToShow = promptsToSet.slice(0, promptsPerPage);
  setPromptsToShow(promptsToShow);

  setIsLoadingPrompts(false);
};
