import styles from "./Pagination.module.scss";

const Pagination = ({
  handlePageChange,
  currentPage,
  promptsLength,
  itemsPerPage = 15,
}) => {
  const totalPages = Math.ceil(promptsLength / itemsPerPage);
  const visiblePageNumbers = [];
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  for (let page = startPage; page <= endPage; page++) {
    visiblePageNumbers.push(page);
  }

  return (
    <div className={styles.pagination}>
      <button
        onClick={() => handlePageChange(1)}
        className={styles.paginationButton}
        style={{ fontSize: "20px" }}
        disabled={currentPage === 1}
      >
        &laquo;&laquo;
      </button>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        className={styles.paginationButton}
        style={{ fontSize: "20px" }}
        disabled={currentPage === 1}
      >
        &laquo;
      </button>
      {visiblePageNumbers.map((page) => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={`${styles.paginationButton} ${
            page === currentPage ? styles.activePage : ""
          }`}
        >
          {page}
        </button>
      ))}
      {currentPage < totalPages - 2 && (
        <span className={styles.paginationDots}>...</span>
      )}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        className={styles.paginationButton}
        style={{ fontSize: "20px" }}
        disabled={currentPage === totalPages}
      >
        &raquo;
      </button>
      <button
        onClick={() => handlePageChange(totalPages)}
        className={styles.paginationButton}
        style={{ fontSize: "20px" }}
        disabled={currentPage === totalPages}
      >
        &raquo;&raquo;
      </button>
    </div>
  );
};

export default Pagination;
