import React from "react";

function CloseIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="1.5"></circle>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M14.5 9.5l-5 5m0-5l5 5"
      ></path>
    </svg>
  );
}

export default CloseIcon;
