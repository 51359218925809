import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppLoading from "../../components/Loading/AppLoading";
import { useAuth } from "../../context/AuthContext";
import { getUser } from "../../services/auth";
import {
  getTokenFromCookies,
  removeTokens,
} from "../../services/cookies/tokenCookies";
import { generateHmac, generateState } from "../../utils/oAuthSettings";

const PROMPTBOOK_KEY = process.env.REACT_APP_PROMPTBOOK_KEY;

export function RequireAuth({ children }) {
  const navigate = useNavigate();
  const { updateUser, user, loginWithCode, isLoading } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const token = getTokenFromCookies();

    const handleOAuth = async (authCode) => {
      const newState = generateState();
      const newHmac = generateHmac(newState, PROMPTBOOK_KEY || "");
      window.history.replaceState(null, "", window.location.pathname);

      loginWithCode(authCode, newState, newHmac);
    };

    const fetchUser = async () => {
      if (!user && token) {
        const userData = await getUser();
        if (userData) {
          updateUser(userData);
        }
      } else if (!token) {
        removeTokens();
        navigate("/login");
      }
    };

    code ? handleOAuth(code) : fetchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!user || isLoading) return <AppLoading />;

  // if (!loggedIn) {
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  return children;
}
