import React, { memo, useState, useEffect } from "react";
import styles from "./MidjourneyPromptsItem.module.scss";

const MidjourneyPromptsItem = memo(({ prompt, onCopy, onOpenModal }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const openModalHandler = () => {
    onOpenModal(prompt);
  };

  useEffect(() => {
    const image = new Image();
    image.src = prompt.imgUrl;
    image.onload = () => {
      setImageLoaded(true);
    };

    return () => {
      image.onload = null;
    };
  }, [prompt.imgUrl]);

  return (
    <div className={styles.prompt} onClick={openModalHandler}>
      <div className={styles.promptImage}>
        {imageLoaded ? (
          <img src={prompt.imgUrl} alt="Midjourney Prompt" />
        ) : (
          <div className={styles.placeholderShimmer} />
        )}
      </div>
      <div className={styles.promptDetails}>
        <p className={styles.subCategory}>{prompt.title}</p>
        <div className={styles.promptContent}>
          <p>{prompt.main_description}</p>
        </div>
      </div>
    </div>
  );
});

export default MidjourneyPromptsItem;
