import { getFromCookies, setCookie } from "./cookies";

const TOKEN_EXPIRES_DATE = "Fri, 31 Dec 9999 23:59:59 GMT";

//token
const saveTokenCookie = (token) => {
  setCookie("token", token, {
    path: "/",
    expires: TOKEN_EXPIRES_DATE,
    secure: true,
  });
};

const getTokenFromCookies = () => {
  return getFromCookies("token");
};

const removeTokenCookie = () => {
  setCookie("token", "", {
    path: "/",
    expires: "Thu, 01 Jan 1970 00:00:00 UTC",
  });
};

//refresh token
const saveRefreshTokenCookie = (refreshToken) => {
  setCookie("refreshToken", refreshToken, {
    path: "/",
    expires: TOKEN_EXPIRES_DATE,
    secure: true,
  });
};

const getRefreshTokenFromCookies = () => {
  return getFromCookies("refreshToken");
};

const removeRefreshTokenCookie = () => {
  setCookie("refreshToken", "", {
    path: "/",
    expires: "Thu, 01 Jan 1970 00:00:00 UTC",
  });
};

//campus token
const saveCampusTokenCookie = (refreshToken) => {
  setCookie("campusAccessToken", refreshToken, {
    path: "/",
    expires: TOKEN_EXPIRES_DATE,
    secure: true,
  });
};

const removeCampusTokenCookie = () => {
  setCookie("campusAccessToken", "", {
    path: "/",
    expires: "Thu, 01 Jan 1970 00:00:00 UTC",
  });
};

//all
const removeTokens = () => {
  removeTokenCookie();
  removeRefreshTokenCookie();
  removeCampusTokenCookie();
};

const parseJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

export {
  getTokenFromCookies,
  saveTokenCookie,
  removeTokenCookie,
  saveRefreshTokenCookie,
  getRefreshTokenFromCookies,
  removeRefreshTokenCookie,
  saveCampusTokenCookie,
  removeCampusTokenCookie,
  removeTokens,
  parseJwt,
};
