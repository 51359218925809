import CategoryAccordion from "../../../../components/CategoryAccordion";
import styles from "./MidjourneyPromptsFilters.module.scss";

const MidjourneyPromptsFilters = ({
  handleFormSubmit,
  searchingValue,
  handleSearchingValueChange,
  isLoadingMidjourneyPrompts,
  categories,
  handleCategoryChange,
  entireListOfPrompts,
  selectedCategory,
  handleResetFilters,
}) => {
  return (
    <div className={styles.midjourneyPromptsFilters}>
      <form onSubmit={handleFormSubmit} className={styles.filtersForm}>
        <input
          type="text"
          value={searchingValue}
          onChange={handleSearchingValueChange}
          placeholder="Wyszukaj prompty..."
          className={styles.searchInput}
        />
        <button
          type="submit"
          className={styles.searchButton}
          disabled={isLoadingMidjourneyPrompts}
        >
          Wyszukaj
        </button>
      </form>
      <div className={styles.categories}>
        <p>Dostępne Kategorie:</p>
        <div className={styles.desktopCategories}>
          {categories.map((categoryObj, index) => (
            <div
              key={index}
              onClick={() =>
                handleCategoryChange({
                  category: categoryObj.category,
                  type: "main",
                })
              }
              className={`${styles.category} ${
                selectedCategory.category === categoryObj.category
                  ? styles.activeCategory
                  : ""
              }`}
            >
              {categoryObj.category} (
              {
                entireListOfPrompts.filter(
                  (prompt) => prompt.category === categoryObj.category
                ).length
              }
              )
            </div>
          ))}
        </div>
        <div className={styles.mobileCategories}>
          <CategoryAccordion
            categories={categories}
            prompts={entireListOfPrompts}
            handleCategoryChange={handleCategoryChange}
            selectedCategory={selectedCategory}
            withSubs={false}
          />
        </div>
      </div>
      <button
        onClick={handleResetFilters}
        className={styles.resetFiltersButton}
      >
        Resetuj filtry
      </button>
    </div>
  );
};

export default MidjourneyPromptsFilters;
