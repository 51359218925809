export const extractTextBetweenSquareBrackets = (text) => {
  const regex = /\[\[(.*?)\]\]/g;
  const matches = new Map();

  let match;
  while ((match = regex.exec(text)) !== null) {
    const label = match[0];
    if (!matches.has(label)) {
      matches.set(label, { label, value: "" });
    }
  }

  return [...matches.values()];
};
