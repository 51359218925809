import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://c72b81270dae856f1388037d98c4fac5@o4508217820971008.ingest.de.sentry.io/4508261452349521",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  environment:
    window.location.hostname === "localhost" ||
    window.location.hostname.includes("devpromptbook")
      ? "development"
      : "production",
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // Środowisko developerskie
    // "localhost",
    // "localhost:3000",
    /^https:\/\/devpromptbook\.campusai\.pl/,
    /^https:\/\/plugindev\.campusai\.pl/,

    // Środowisko produkcyjne
    /^https:\/\/promptbook\.campusai\.pl/,
    /^https:\/\/plugin\.campusai\.pl/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
