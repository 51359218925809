import React from "react";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../context/AuthContext";
import styles from "./Login.module.scss";
// import { handleLoginFormSubmit } from "./utils";
import { generateHmac, generateState } from "../../utils/oAuthSettings";

const OAUTH_SERVICE_URL = process.env.REACT_APP_OAUTH_SERVICE_URL;
const PROMPTBOOK_KEY = process.env.REACT_APP_PROMPTBOOK_KEY;
const OAUTH_APP_ID = process.env.REACT_APP_OAUTH_APP_ID;

function LoginPage() {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const navigate = useNavigate();
  // const { updateUser } = useAuth();

  //wysyłanie do oauth pierwszym razem
  // const state = generateState();
  // const hmac = generateHmac(state, PROMPTBOOK_KEY || "");

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const code = params.get("code");

  //   if (code) {
  //     const newState = generateState();
  //     const newHmac = generateHmac(newState, PROMPTBOOK_KEY || "");

  //     loginWithCode(code, newState, newHmac).catch((error) => {
  //       console.error("Błąd logowania:", error);
  //     });
  //   }
  // }, [loginWithCode]);

  const handleOAuthLogin = () => {
    const state = generateState();
    const hmac = generateHmac(state, PROMPTBOOK_KEY || "");
    const oauthUrl = `${OAUTH_SERVICE_URL}/oauth?app_id=${OAUTH_APP_ID}&state=${state}&hmac=${hmac}`;
    window.location.href = oauthUrl;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src="/bg-promptbook.jpg" alt="" className={styles.introImage} />
        <div
          // onSubmit={(e) =>
          //   handleLoginFormSubmit(e, password, navigate, email, updateUser)
          // }
          className={styles.loginForm}
        >
          <div className={styles.logo}>
            <img src="/logo-header.svg" alt="Logo" />
          </div>
          {/* <h2 className={styles.header}>Zaloguj się</h2> */}
          {/* <div className={styles.inputs}>
            <input
              type="text"
              id="email-input"
              placeholder="Twój email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              id="password-input"
              placeholder="Twoje hasło"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div> */}
          <button
            type="submit"
            className={styles.submitButton}
            onClick={handleOAuthLogin}
          >
            Zaloguj się z District Key
          </button>
          <div className={styles.orDivider}>
            <span>LUB</span>
          </div>
          <div className={styles.additionalButtonsWrapper}>
            <a
              href="https://app.campusai.pl/join"
              className={styles.additionalButtons}
            >
              Załóż konto
            </a>
            <a
              href="https://app.campusai.pl/recovery"
              className={styles.additionalButtons}
            >
              Nie pamiętasz hasła?
            </a>
            {/* <a
              href={`${OAUTH_SERVICE_URL}/oauth?app_id=${OAUTH_APP_ID}&state=${state}&hmac=${hmac}`}
              className={styles.additionalButtons}
            >
              Zaloguj z Oauth Service
            </a> */}
          </div>

          <a href="https://www.campusai.pl" className={styles.returnButton}>
            Powrót do CampusAI
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
