import React from "react";
import styles from "./NotFound.module.scss";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className={styles.notFound}>
      <h2>404 - Nie znaleziono strony</h2>
      <p>Przepraszamy, ale strona, której szukasz, nie istnieje.</p>
      <Link to="/">Wróć na stronę główną</Link>
    </div>
  );
};

export default NotFoundPage;
