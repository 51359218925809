import React from "react";

function DeleteIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 56 56"
      {...props}
    >
      <path d="M9.965 49.574l20.343-.023c2.743 0 5.11-.844 7.032-2.86l13.828-14.273c1.547-1.57 2.227-3 2.227-4.453 0-1.477-.68-2.883-2.227-4.453L37.363 9.168c-1.922-2.04-4.289-2.742-7.031-2.742H9.965c-4.875 0-7.36 2.414-7.36 7.265V42.31c0 4.851 2.485 7.265 7.36 7.265zm.07-3.773c-2.344 0-3.656-1.242-3.656-3.68V13.88c0-2.438 1.312-3.68 3.656-3.68h20.367c1.758 0 2.93.305 4.125 1.547l13.711 14.227c.774.82 1.055 1.383 1.055 1.992 0 .586-.258 1.148-1.055 1.969l-13.734 14.18c-1.219 1.265-2.344 1.687-4.125 1.687zm5.86-8.203c.515 0 .913-.164 1.242-.492l6.609-6.633 6.61 6.633c.304.328.726.492 1.218.492 1.008 0 1.828-.797 1.828-1.782 0-.492-.21-.914-.562-1.265l-6.54-6.586 6.54-6.563c.351-.351.562-.797.562-1.265 0-1.008-.843-1.852-1.828-1.852-.469 0-.89.211-1.242.563l-6.586 6.586-6.586-6.586c-.352-.352-.773-.563-1.266-.563-.984 0-1.804.844-1.804 1.852 0 .468.187.914.539 1.265l6.562 6.563-6.562 6.586a1.737 1.737 0 00-.54 1.265c0 .985.798 1.782 1.805 1.782z"></path>
    </svg>
  );
}

export default DeleteIcon;
